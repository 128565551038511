var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{class:{
      [_vm.$style.card__register]: true,
    }},[_c('div',{class:{
        [_vm.$style.card__register__image]: true,
      }},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"width":"250","height":"auto"}})]),_c('div',{staticClass:"pbz-font subtitle-md-medium mt-5 text-left",staticStyle:{"color":"#1a1a1a"}},[_vm._v(" Lupa Kata Sandi ")]),_c('a-col',{staticClass:"my-3 d-flex justify-content-start align-items-center",attrs:{"span":24}},[_c('div',{staticClass:"d-flex justify-content-around align-items-center",staticStyle:{"width":"100%","height":"48px","background":"#ffffff","border":"1px solid #cccccc","box-sizing":"border-box","border-radius":"80px","cursor":"pointer","font-size":"13px","padding":"5px"}},_vm._l((_vm.types),function(item,index){return _c('div',{key:index,staticClass:"pbz-font button-xs-medium",staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","align-items":"center","padding":"10px 20px","width":"45%","height":"38px","color":"#999999 !important"},style:([
            _vm.activeInvitation === item.value && {
              background: '#E00000',
              boxShadow: 'rgb(0 0 0 / 14%) 0px 0px 5px',
              color: '#FFFFFF !important',
              borderRadius: '60px',
            },
          ]),on:{"click":function($event){return _vm.changeActiveTypes(item.value)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),0)]),_c('div',{staticStyle:{"margin-top":"100px"}},[_c('a-form-model',{ref:"ruleForm",staticClass:"custom-forgot",attrs:{"model":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-model-item',{class:{
            [_vm.$style.container__form]: true,
          },attrs:{"prop":"username","rules":[{
            type: _vm.labelForm === 'Email' ? 'email' : 'string',
            required: true,
            message: _vm.labelForm === 'Email'
              ? 'Email is not valid'
              : `${_vm.labelForm} is required`,
            trigger: 'blur',
          }]}},[_c('a-input-group',{staticClass:"forgot-password d-flex"},[(_vm.activeInvitation == 'PHONE')?_c('a-select',{model:{value:(_vm.phoneCodeCountry),callback:function ($$v) {_vm.phoneCodeCountry=$$v},expression:"phoneCodeCountry"}},_vm._l((_vm.codeCountry),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.value}},[_vm._v(" +"+_vm._s(item.value)+" ")])}),1):_vm._e(),_c('a-input',{class:{
                [_vm.$style.container__form__input]: true,
              },style:({
                borderLeft: _vm.activeInvitation == 'PHONE' ? 'none' : '',
                textAlign: 'left'
              }),attrs:{"max-length":_vm.maxLength,"placeholder":_vm.labelForm},on:{"keypress":_vm.numberOnly},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})],1)],1),_c('a-button',{staticClass:"pbz-font body-md-bold text-center w-100 mt-3",class:{
            [_vm.$style.button__submit]: true,
          },attrs:{"size":"large","html-type":"submit"}},[(!_vm.loading)?_c('div',[_vm._v(" Ganti Kata Sandi ")]):_vm._e(),(_vm.loading)?_c('div',[_c('a-icon',{attrs:{"type":"loading"}}),_vm._v(" Loading ")],1):_vm._e()]),_c('div',{staticClass:"mt-5 pbz-font button-md-medium",staticStyle:{"color":"#e00000","cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.$keycloak.login()}}},[_vm._v(" Kembali Ke Login ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="row">
    <div
      :class="{
        [$style.card__register]: true,
      }"
    >
      <div
        :class="{
          [$style.card__register__image]: true,
        }"
      >
        <img
          src="@/assets/images/logo.svg"
          width="250"
          height="auto"
        />
      </div>

      <div
        class="pbz-font subtitle-md-medium mt-5 text-left"
        style="color: #1a1a1a"
      >
        Lupa Kata Sandi
      </div>

      <a-col
        :span="24"
        class="my-3 d-flex justify-content-start align-items-center"
      >
        <div
          style="
            width: 100%;
            height: 48px;
            background: #ffffff;
            border: 1px solid #cccccc;
            box-sizing: border-box;
            border-radius: 80px;
            cursor: pointer;
            font-size: 13px;
            padding: 5px;
          "
          class="d-flex justify-content-around align-items-center"
        >
          <div
            v-for="(item, index) in types"
            :key="index"
            style="
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              padding: 10px 20px;
              width: 45%;
              height: 38px;
              color: #999999 !important;
            "
            :style="[
              activeInvitation === item.value && {
                background: '#E00000',
                boxShadow: 'rgb(0 0 0 / 14%) 0px 0px 5px',
                color: '#FFFFFF !important',
                borderRadius: '60px',
              },
            ]"
            class="pbz-font button-xs-medium"
            @click="changeActiveTypes(item.value)"
          >
            {{ item.label }}
          </div>
        </div>
      </a-col>

      <div class="" style="margin-top: 100px">
        <a-form-model ref="ruleForm" :model="form" class="custom-forgot" @submit.prevent="handleSubmit">
          <a-form-model-item
            prop="username"
            :rules="[{
              type: labelForm === 'Email' ? 'email' : 'string',
              required: true,
              message: labelForm === 'Email'
                ? 'Email is not valid'
                : `${labelForm} is required`,
              trigger: 'blur',
            }]"
            :class="{
              [$style.container__form]: true,
            }"
          >
            <a-input-group class="forgot-password d-flex">
              <a-select
                v-if="activeInvitation == 'PHONE'"
                v-model="phoneCodeCountry"
              >
                <a-select-option
                  v-for="(item, index) in codeCountry"
                  :key="index"
                  :value="item.value"
                >
                  +{{ item.value }}
                </a-select-option>
              </a-select>
              <a-input
                v-model="form.username"
                :max-length="maxLength"
                :placeholder="labelForm"
                :class="{
                  [$style.container__form__input]: true,
                }"
                :style="{
                  borderLeft: activeInvitation == 'PHONE' ? 'none' : '',
                  textAlign: 'left'
                }"
                @keypress="numberOnly"
              />
            </a-input-group>
          </a-form-model-item>

          <a-button
            size="large"
            class="pbz-font body-md-bold text-center w-100 mt-3"
            :class="{
              [$style.button__submit]: true,
            }"
            html-type="submit"
          >
            <div v-if="!loading">
              Ganti Kata Sandi
            </div>
            <div v-if="loading">
              <a-icon type="loading" /> Loading
            </div>
          </a-button>

          <div
            class="mt-5 pbz-font button-md-medium"
            style="color: #e00000; cursor: pointer"
            @click.prevent="$keycloak.login()"
          >
            Kembali Ke Login
          </div>
        </a-form-model>
      </div>
    </div>
  </div>
</template>

<script>
const types = [
  {
    label: 'Email',
    value: 'EMAIL',
  },
  {
    label: 'Username',
    value: 'USERNAME',
  },
  {
    label: 'No. Handphone',
    value: 'PHONE',
  },
]

export default {
  name: 'CuiForgotPassword',
  data: function () {
    return {
      types,
      activeInvitation: 'EMAIL',
      form: {
        username: '',
      },
      phoneCodeCountry: '62',
      codeCountry: [
        {
          label: 'Indonesia',
          value: '62',
        },
        {
          label: 'Malaysia',
          value: '65',
        },
      ],
    }
  },
  computed: {
    loading() {
      return this.$store.state.user.loading
    },
    labelForm() {
      return (
        this.types.find((f) => f.value === this.activeInvitation)?.label ??
        'Email'
      )
    },
    maxLength() {
      return this.labelForm === 'No. Handphone' ? 11 : 100
    },
  },
  methods: {
    numberOnly(event) {
      if (this.labelForm === 'No. Handphone') {
        const keyCode = (event.which) ? event.which : event.keyCode
        
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
          event.preventDefault()
        }
      }
    },
    changeActiveTypes(value) {
      this.activeInvitation =
      this.types.find((f) => f.value === value)?.value ?? 'EMAIL'
      if(this.$refs.ruleForm) this.$refs.ruleForm.resetFields()
    },
    handleSubmit(e) {
      e.preventDefault()
      this.$refs.ruleForm.validate(valid => {
        if(valid) {
          const getUrl = new URL(window.location.href)

          this.$store
            .dispatch('user/REQUESTFORGOTPASSWORD', {
              username:
                this.activeInvitation === 'PHONE'
                  ? this.phoneCodeCountry + this.form.username
                  : this.form.username,
              type: this.activeInvitation,
              setup_password_url:
                getUrl.origin + '/auth/forgot-password/reset-password',
            })
            .then(() => {
              this.$router.push({ path: '/auth/forgot-password/otp' })
            })
            .catch((err) => {
              this.$notification.error({
                message: err.response.data.message,
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
@import '@/components/cleanui/system/Auth/style.module.scss';
</style>
<style lang="scss">
.forgot-password {
  .ant-select-selection.ant-select-selection--single {
    height: 100%;
    width: 70px;
    border-right: none;
    border-bottom-right-radius: 0;
  }
  .ant-select-selection-selected-value {
    margin-top: 6px;
  }
}
.custom-forgot .ant-form-explain {
  text-align: left;
  margin-top: 3px;
}
.custom-forgot .ant-form-item {
  margin-bottom: 15px;
}

.custom-forgot .ant-select-selection,
.custom-forgot .ant-select-focused .ant-select-selection,
.custom-forgot .ant-select-selection:hover  {
  border-color: #e4e9f0;
  border-radius: 4px 0px 0px 4px;
  box-shadow: none;
}
.custom-forgot .ant-form-item-control:not(.has-error) .forgot-password.ant-input-group input:focus,
.custom-forgot .ant-form-item-control:not(.has-error) .forgot-password.ant-input-group input:hover {
  border-color: #e4e9f0;
}
</style>
